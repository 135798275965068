<template>
  <div>
    <b-card bg-variant="light" header="Recursos Naturales" header-bg-variant="primary" header-text-variant="white" align="center" >
        
        <b-form @submit="modalEnviar" @reset="cancelar" align="left" id="formularioResiduos">
            <b-form-row>
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-suelo"
                        label="Afecta o no el suelo"
                        label-for="suelo"
                    >
                        <b-form-select 
                            id="suelo" 
                            v-model="form.suelo" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-flora"
                        label="Afecta o no la flora"
                        label-for="flora"
                    >
                        <b-form-select 
                            id="flora" 
                            v-model="form.flora" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-fauna"
                        label="Afecta o no la fauna"
                        label-for="fauna"
                    >
                        <b-form-select 
                            id="fauna" 
                            v-model="form.fauna" 
                            class="mb-3" 
                            required 
                            :disabled="verDetalles?true:false"
                        >
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-otro_cual"
                        label="Afecta otro recurso natural. cual?"
                        label-for="otro_cual"
                    >
                        <b-form-input
                            id="otro_cual"
                            v-model="form.otro_cual"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" class="text-right">
                    <b-button type="submit" variant="primary" class="mr-2" :disabled="verDetalles?true:false">
                        Guardar
                        </b-button>
                    <b-button type="reset" >Cancelar</b-button>
                </b-col>
            </b-form-row>

        </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {encontrarError} from '../../../contantesErrores';
  export default {
      name: 'RecursosNaturales',
    data() {
      return {
        editar:false,
        form: {
            id: null,
            suelo: null,
            flora: null,
            fauna: null,
            otro_cual:null,
        },
      }
    },
    async mounted(){
        if(this.visitaEnGestion.id){
            this.form.id=this.visitaEnGestion.id
            await this.cargarRecursosNaturales(this.visitaEnGestion.id)
        }
    },
    computed:{
        ...mapState('gestionarVisitas', ['verDetalles','visitaEnGestion']),
        ...mapState('gestionarVisitasVigilanciaControl', ['resultRecursosNaturales', 'recursosNaturalesCargados' ]),
    },
    methods: {
        ...mapActions(['activarLoading']),
        ...mapActions('gestionarVisitasVigilanciaControl', ['crearRecursosNaturales', 'cargarRecursosNaturalesById', 'editarRecursosNaturales']),

        async cargarRecursosNaturales(id){
            this.activarLoading(true)
            try{
                await this.cargarRecursosNaturalesById(id)
                console.log('esto es lo cargado', this.recursosNaturalesCargados[0])
                if(this.recursosNaturalesCargados.length>0){
                    this.editar=true
                    this.form=this.recursosNaturalesCargados[0]
                    
                }
                this.activarLoading(false)
            }catch(e){
                encontrarError(this, e.data)
            }
        },

        modalEnviar(event){
            event.preventDefault()
             this.$bvModal.msgBoxConfirm('Se van a guardar los datos de Recursos Naturales, ¿Desea continuar?.', {
                title: 'Por favor confirme',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Continuar',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        this.onSubmit()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async onSubmit() {
            this.activarLoading(true)

            let dataGuardar = {
                recursonatural: this.form
            }

             try{
                 if(this.editar){
                     await this.editarRecursosNaturales(dataGuardar)
                 }else{
                     await this.crearRecursosNaturales(dataGuardar)
                 }
                if(this.resultRecursosNaturales.data.status==200){
                    this.activarLoading(false)
                    this.modalOk()
                }
            }catch(e){
                this.activarLoading(false)
                encontrarError(this, e.data)
            }
        },

        modalOk(){
            this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                title: 'Confirmación',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    // this.$router.push({name: 'gestionVisita'})
                    this.cargarRecursosNaturales(this.visitaEnGestion.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        
        cancelar(){
            this.$bvModal.msgBoxOk('Se perderán los datos sin guardar.', {
                title: 'Cancelar',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    this.$router.push({name: 'gestionarVisitaVigilanciaControl'})
                })
                .catch(err => {
                    console.log(err)
                })
        }, 
    }
  }
</script>

<style scoped>
    
</style>